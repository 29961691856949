import 'tailwind-config/main.css'
import { useRouter } from 'next/router'
import { Manrope } from 'next/font/google'
import clsx from 'clsx'
import { apiPlugin, storyblokInit } from '@storyblok/react'
import { Components } from '@components/common/DynamicComponents/DynamicComponent'
import { ExtendedAppProps } from './types'
import { GTM, SEO, Toast } from 'ui'
import { ToastStoreProvider, usePageTitleBase } from 'common'
import { appWithTranslation, useTranslation } from 'next-i18next'
import nextI18NextConfig from '../../next-i18next.config.js'
import Script from 'next/script'
import { DomainStoreProvider, useDomain } from '@hooks/useDomainStore'
import { isEnglish } from '@utils/locale'
import { Language, lookups } from '@constants/language'

const accessToken =
	process.env.STORYBLOK_KEY || 'used-only-to-prevent-client-console-error'

storyblokInit({
	accessToken,
	use: [apiPlugin],
	components: Components,
	apiOptions: {
		cache: {
			clear: 'auto',
			type: 'none',
		},
	},
})

const font = Manrope({
	variable: '--base-font-family',
	subsets: ['latin', 'cyrillic', 'greek'],
})

function App({ Component, pageProps }: ExtendedAppProps) {
	const router = useRouter()
	const { seo, gtm, ...rest } = pageProps ?? {}
	const getLayout = Component.getLayout ?? ((page) => page)

	const domainData = useDomain()

	const { t, i18n } = useTranslation()

	const { host, origin, initialLocale } = domainData

	const locale = isEnglish(initialLocale) ? `/${initialLocale}` : ''

	const { countryName } = lookups({ language: i18n.language as Language })

	const pageTitle = usePageTitleBase(t, countryName)

	const url = `${origin}${locale}${router.asPath}`

	return (
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `
						:root {
							--base-font-family: ${font.style.fontFamily};
						}
					`,
				}}
			/>
			<SEO
				seo={{
					...seo,
					site_name: seo.site_name,
					metatags: seo.metatags,
					locale: initialLocale,
					domain: host,
					url,
				}}
				pageTitle={seo.metatags.title || pageTitle}
			/>
			<GTM gtm={gtm} />
			<Script
				strategy="afterInteractive"
				src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
			/>
			<div className={clsx(font.variable, 'font-sans')}>
				<DomainStoreProvider url={url} {...domainData}>
					<ToastStoreProvider>
						{getLayout(<Component {...rest} />)}
						<Toast />
					</ToastStoreProvider>
					<div id="modal" />
				</DomainStoreProvider>
			</div>
		</>
	)
}

export default appWithTranslation(App, nextI18NextConfig)
