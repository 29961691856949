import { CSSProperties } from 'react'
import { AnimatePresence } from 'framer-motion'
import clsx from 'clsx'
import { z } from 'zod'
import {
	AbstractButton,
	Button,
	CirclePlus,
	Modal,
	OptimizedImage,
	Richtext,
} from 'ui'
import { InfoCardSchema } from './InfoCard.schema'
import { useModal } from 'common'

type InfoCardProps = {
	blok: z.TypeOf<typeof InfoCardSchema>
	columnsConfig?: {
		desktop: string
		tablet: string
	}
}

const getImgType = (url: string) => {
	return url?.split('.').pop()
}

const InfoCard = ({ blok, columnsConfig }: InfoCardProps) => {
	const {
		title,
		description,
		tag,
		image,
		image_right,
		button_text,
		button_url,
	} = blok
	const isFullWidth = columnsConfig && columnsConfig.desktop === 'full'
	const { modal, openModal, closeModal } = useModal()

	const infoPart = () => {
		return (
			<div
				className={clsx(
					'relative flex flex-col rounded-sm bg-white sm:h-[272px] md:h-[344px] lg:h-[376px]',
					!image?.filename
						? 'w-full sm:p-4 md:p-6 lg:p-8'
						: isFullWidth
						? 'w-1/2 sm:w-full sm:px-10 sm:py-8 md:px-10 md:py-8 lg:px-12 lg:py-10'
						: 'w-2/3 sm:w-full sm:p-4 md:p-6 lg:p-8'
				)}
			>
				{tag && <p className="p_highlight text-primary line-clamp-2">{tag}</p>}
				<h3 className="title word_break line-clamp-4">{title}</h3>
				<div className="mt-auto flex w-full justify-between sm:gap-x-6">
					<AbstractButton
						onClick={() => openModal('default')}
						className="p_highlight mr-auto"
						aria-label="Show more"
					>
						<CirclePlus />
					</AbstractButton>
					{button_text && (
						<Button
							href={button_url}
							theme="colored"
							className="ml-auto sm:w-full"
						>
							{button_text}
						</Button>
					)}
				</div>
			</div>
		)
	}

	const theme = blok.theme || 'accent-orange'

	return (
		<>
			<AnimatePresence>
				{modal === 'default' && (
					<Modal title={tag} onClose={closeModal}>
						<Modal.Body>
							<h3 className="title_large mb-2">{title}</h3>
							{typeof description === 'string' ? (
								<p>{description}</p>
							) : 'content' in description ? (
								<Richtext text={description} />
							) : (
								description
							)}
						</Modal.Body>
						{button_text && (
							<Modal.Bottom>
								<Modal.PrimaryButton href={button_url}>
									{button_text}
								</Modal.PrimaryButton>
							</Modal.Bottom>
						)}
					</Modal>
				)}
			</AnimatePresence>
			{image?.filename ? (
				<div
					className="flex sm:flex-col"
					{...(theme !== 'accent-orange' &&
						theme !== 'default' && {
							style: {
								'--primary': `var(--${theme})`,
							} as CSSProperties,
						})}
				>
					<div
						className={clsx(
							`bg-primary relative text-white sm:min-h-[272px]`,
							isFullWidth ? 'w-1/2 sm:w-full' : 'w-1/3 sm:w-full',
							image_right && 'order-1'
						)}
					>
						<OptimizedImage
							src={image.filename}
							alt={title}
							className={
								getImgType(image.filename) === 'png' ||
								getImgType(image.filename) === 'svg'
									? 'm-auto max-h-[70%] max-w-[70%] object-contain'
									: 'object-cover'
							}
							fill
							sizes="(max-width: 819px) 100vw, 50vw"
						/>
					</div>
					{infoPart()}
				</div>
			) : (
				<div
					className="w-full"
					{...(theme !== 'accent-orange' &&
						theme !== 'default' && {
							style: {
								'--primary': `var(--${theme})`,
								'--primary-hover': `var(--${theme})`,
							} as CSSProperties,
						})}
				>
					{infoPart()}
				</div>
			)}
		</>
	)
}

export default InfoCard
