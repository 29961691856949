import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/compat/router'

type Options = {
	closeOnRouteChange?: boolean
}

export function useModal<TModal extends string = 'default'>({
	closeOnRouteChange = true,
}: Options = {}) {
	const [modal, setModal] = useState<null | TModal>(null)
	const { events } = useRouter() ?? {}

	const closeModal = useCallback(() => setModal(null), [])
	const openModal = useCallback((modal: TModal) => setModal(modal), [])

	useEffect(() => {
		if (!events || !closeOnRouteChange) {
			return
		}

		events.on('routeChangeStart', closeModal)
		return () => {
			events.off('routeChangeStart', closeModal)
		}
	}, [closeModal, closeOnRouteChange, events])

	return { modal, openModal, closeModal }
}
