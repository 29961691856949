import { ReactElement, ComponentPropsWithoutRef } from 'react'
import {
	FieldValues,
	FormProvider,
	SubmitHandler,
	UseFormReturn,
} from 'react-hook-form'
import clsx from 'clsx'
import { useAlertIfUnsavedChanges } from 'common'

interface FormProps<T extends FieldValues>
	extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
	form: UseFormReturn<T>
	t: (value: string) => string
	onSubmit?: SubmitHandler<T>
	unsavedAlertCopy?: string
	wrapperClass?: string
	canUseAlert?: false
	disabled?: boolean
}

export const Form = <T extends FieldValues>({
	form,
	onSubmit,
	t,
	children,
	className,
	wrapperClass,
	unsavedAlertCopy,
	canUseAlert,
	disabled,
	...props
}: FormProps<T>): ReactElement => {
	const { isDirty, isSubmitting, isValid, isSubmitSuccessful } = form.formState

	const shouldUseAlert =
		(isSubmitting && isValid) || isSubmitSuccessful ? false : isDirty
	useAlertIfUnsavedChanges(canUseAlert ?? shouldUseAlert, t, unsavedAlertCopy)

	return (
		<FormProvider {...form}>
			<form
				className={clsx(wrapperClass)}
				{...(onSubmit
					? { onSubmit: form.handleSubmit(onSubmit) }
					: { onSubmit: (event) => event.preventDefault() })}
				{...props}
			>
				<fieldset
					disabled={disabled || form.formState.isSubmitting}
					className={clsx(className)}
				>
					{children}
				</fieldset>
			</form>
		</FormProvider>
	)
}
