import { useCallback, useRef } from 'react'
import { captureException } from '@sentry/nextjs'
import { useToast } from 'common'
import { isLocal } from 'configs'

export const useRecaptcha = (action: string, t: (value: string) => string) => {
	const scoreTokenRef = useRef<null | string>(null)
	const checkboxTokenRef = useRef<null | string>(null)

	const widgetId = useRef<null | number>(null)

	const { pushInfo } = useToast()

	const hasWidget = widgetId.current !== null

	const handleCheckboxRecaptcha = useCallback(() => {
		try {
			widgetId.current = grecaptcha.enterprise.render(
				'recaptcha-checkbox-container',
				{
					// It shows action doesn't exist, but it does... https://cloud.google.com/recaptcha-enterprise/docs/instrument-web-pages-with-checkbox
					// @ts-ignore
					action,
					sitekey: '6LcQS1EpAAAAAPQ5EpQxeEgQ6oYQNTGznMI8765v',
					// sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_CHECKBOX_KEY as string,
					callback: (token) => {
						checkboxTokenRef.current = token
					},
				}
			)
		} catch (e: any) {
			captureException(e, {
				extra: {
					formattedMessage: `Error while trying to get reCaptcha checkbox token: ${e?.message}`,
				},
			})
		}
	}, [action])

	const handleScoreRecaptcha = useCallback(async () => {
		try {
			scoreTokenRef.current = await new Promise((res, rej) => {
				try {
					grecaptcha.enterprise.ready(async () => {
						const token = await grecaptcha.enterprise?.execute(
							process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string,
							{
								action,
							}
						)

						res(token)
					})
				} catch (e: any) {
					rej(e?.message)
				}
			})
		} catch (e: any) {
			captureException(e, {
				extra: {
					formattedMessage: `Error while trying to get reCaptcha token: ${e?.message}`,
				},
			})
		}
	}, [action])

	const handleRecaptcha = useCallback(
		async (type: 'score' | 'checkbox') => {
			if (isLocal) {
				return {
					type: 'score',
					token: 'dummy-token',
				}
			}

			if (hasWidget) {
				return {
					type: 'checkbox',
					token: checkboxTokenRef.current,
				}
			}

			if (type === 'checkbox') {
				pushInfo(t('Please, verify that you are not a robot'))

				handleCheckboxRecaptcha()
			}

			if (type === 'score') {
				await handleScoreRecaptcha()
			}

			return {
				type: 'score',
				token: scoreTokenRef.current,
			}
		},
		[handleCheckboxRecaptcha, handleScoreRecaptcha, hasWidget, pushInfo, t]
	)

	return handleRecaptcha
}
